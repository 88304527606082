import React from 'react';
import Slider from "react-slick";
import b1 from '../assets/images/b1.jpg';
import b2 from '../assets/images/b2.jpg';
import b3 from '../assets/images/b3.jpg';

const HomeBanner = () => {

    const settings = {
        autoplay: true,
        dots: false,
        slidesToShow: 1,
    };

    return (
        <div className="homebanner">
            <Slider className="slider" {...settings}>
                <div className="slide-item">
                    <img src={b3} />
                </div>
                <div className="slide-item">
                    <img src={b2} />
                </div>
                <div className="slide-item">
                    <img src={b1} />
                </div>
            </Slider>
        </div>
    )
}

export default HomeBanner;