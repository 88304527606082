import { Link } from 'gatsby';
import React from 'react';
import coursebg from '../assets/images/coursebg.jpeg';
// import p1 from '../assets/images/p1.jpeg';
// import p2 from '../assets/images/p2.jpeg';
// import p3 from '../assets/images/p3.jpeg';
// import p4 from '../assets/images/p4.jpeg';


export const allcourese = [
    {
        id: "software-development",
        title: "Software Development",
        paymentUrl: "https://rzp.io/l/I2Om8oBA",
        list: [{
            name: "C, C++, Java",
        }, {
            name: "Scripting"
        }, {
            name: "Python, Pearl , Shell"
        }]
    }, {
        id: "system",
        title: "System",
        paymentUrl: "https://rzp.io/l/I2Om8oBA",
        list: [
            {
                name: "Microsoft based on MCSE"
            },
            {
                name: "VMware"
            }
        ]
    }, {
        id: "networks",
        title: "Networks",
        paymentUrl: "https://rzp.io/l/I2Om8oBA",
        list: [
            {
                name: "Routing & Switching",
            }, {
                name: "Network Security (Firewalls)"
            }, {
                name: "Load Balancing"
            }, {
                name: "Data Centre Switching"
            }, {
                name: "Network Designing"
            }
        ]
    }, {
        id: "cloud",
        title: "Cloud",
        paymentUrl: "https://rzp.io/l/I2Om8oBA",
        list: [
            {
                name: "Google Cloud"
            }, {
                name: "AWS Cloud"
            }, {
                name: "Microsoft Azure Cloud"
            }
        ]
    }]

const Courses = () => {
    return (
        <>
            <div className="courses-list">
                {allcourese.map((item, index) => (
                    <div key={index} className="course">
                        <div className="imageholder">
                            <img src={coursebg} />
                        </div>
                        <div className="content">
                            <h3>{item.title}</h3>
                            <ul className="list">
                                {item.list.map((it, index) => (
                                    <li key={index}>{it.name}</li>
                                ))}
                            </ul>
                            {/* <span>And More...</span> */}
                        </div>
                        {/* <div className="avatars">
                        <div className="avatar"><img src={p1} /></div>
                        <div className="avatar"><img src={p2} /></div>
                        <div className="avatar"><img src={p3} /></div>
                        <div className="avatar"><img src={p4} /></div>
                    </div> */}
                        {/* <div className="actions">
                            <Link to={`/registration?course=${item.id}`} className="btn">Enroll Now</Link>
                        </div> */}

                    </div>
                ))}
            </div>
            <div className="course-action">
                <Link to="/registration" className="btn">Enroll Now</Link>
            </div>

        </>
    )
}


export default Courses;