import React, { useState } from 'react';
import { Link, useStaticQuery } from 'gatsby';
import Layout from '../layouts/main';
import HomeBanner from '../components/homebanner';
import Testimonials from '../components/testimonials';
import Courses from '../components/courses';
import Slider from "react-slick";


import university from '../assets/images/university.jpg';
import SEO from '../components/seo';
// import avatar from '../assets/images/avatar.jpg';


const Home = () => {

	const settings = {
        autoplay: true,
        dots: false,
        slidesToShow: 5,
    };

	const [One, setOne] = useState(false);
	const [Two, setTwo] = useState(false);
	const [Three, setThree] = useState(false);
	const [Four, setFour] = useState(false);

	return (
		<Layout>
			<SEO title="Home" />
			<HomeBanner />
			<div className="section features">
				<div className="innerwrap">
					<div className="row">
						<div className="grid-12">
							<div className="quote">
								<p>"One platform, one fee, many careers options"</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="grid-3 grid-sm-6 grid-xs-12">
							<div className="feature-card first">
								<ion-icon name="library-outline"></ion-icon>
								<h3>Objective</h3>
								<p>Recently Covid-19 has affected the education of lot of students and career of the professionals.
									The recent generation has lost almost 2 years of education due to Covid-19. The objective of
									the SavNƏT is to give back to the society, As the founder himself learnt from India and worked
									in India and then migrated to Australia, his mission is the same to the students learning here, we
									are not focusing on one skill, one course, the focus is to develop the IT skills in the field of
									Software development, IT infrastructure, such as Network and the cloud, Communication skills,
									Personality Development, as it is very important to acquire all the skills and then go to the
									market. All Skills, one place, one Fee !</p>
								<span className="toggleview" onClick={(e) => {
									e.preventDefault();
									e.target.parentNode.parentNode.classList.toggle('view');
									setOne(!One);
								}} > {One ? <ion-icon name="remove-circle"></ion-icon> : <ion-icon name="add-circle"></ion-icon> }</span>
							</div>
						</div>
						<div className="grid-3 grid-sm-6 grid-xs-12">
							<div className="feature-card second">
							<ion-icon name="newspaper-outline"></ion-icon>
								<h3>Employment</h3>
								<p>Our Focus is to train the youth who is pursuing a graduation, or has completed his graduation or
									someone already working but wants to grow and get into the IT field, If you are already working
									in another industry but wants to get into IT, then we are the right place for you. Building skills
									with the personality development will land you to the right place, our focus is to train you the
									industry standards and that’s the reason we have combined all courses, we will mentor you for
									the interviews so that you are industry ready and ahead of the others by acquiring the multiple skills, our focus is not just to train but also to ensure you get the Job before or after completion
									of your course.</p>
									<span className="toggleview" onClick={(e) => {
									e.preventDefault();
									e.target.parentNode.parentNode.classList.toggle('view');
									setTwo(!Two);
								}} >{Two ? <ion-icon name="remove-circle"></ion-icon> : <ion-icon name="add-circle"></ion-icon> }</span>
							</div>
						</div>
						<div className="grid-3 grid-sm-6 grid-xs-12">
							<div className="feature-card third">
							<ion-icon name="hourglass-outline"></ion-icon>
								<h3>Skills Development</h3>
								<p>Our focus is to ensure developing your skills, once you enroll, you are not limited to one course
									but the entire courses we offer, as the need in the market has grown so as the skills
									requirement has grown, for that we will offer every support you need and we help you to build
									up the required skills and develop the right attitude to secure your job and grow into your career.</p>
									<span className="toggleview" onClick={(e) => {
									e.preventDefault();
									e.target.parentNode.parentNode.classList.toggle('view');
									setThree(!Three);
								}} >{Three ? <ion-icon name="remove-circle"></ion-icon> : <ion-icon name="add-circle"></ion-icon> }</span>
							</div>
						</div>
						<div className="grid-3 grid-sm-6 grid-xs-12">
							<div className="feature-card fourth">
							<ion-icon name="ribbon-outline"></ion-icon>
								<h3>Certificate</h3>
								<p>At the end of your course, you will receive the course completion certificate which will list all the
									courses you have pursued, which you can show into your resume that you are not a one skill
									professional but have the multiple skills.</p>
									<span className="toggleview" onClick={(e) => {
									e.preventDefault();
									e.target.parentNode.parentNode.classList.toggle('view');
									setFour(!Four);
								}} >{Four ? <ion-icon name="remove-circle"></ion-icon> : <ion-icon name="add-circle"></ion-icon> }</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section aboutus">
				<div className="innerwrap">
					<div className="row">
						<div className="grid-6 grid-sm-12 grid-xs-12">
							<div className="section-title">
								<span>About Us</span>
								<h2>Building Skills, Buidling Future</h2>
							</div>
							<div className="section-content">
								<p>At SavNƏT, Our Vision is to build the future IT specialists, Are you doing
Bachelors but do not know where you will land and what you will do, We will help
you building and developing your IT skills which will help you getting into IT, No
matter which stream you are pursing you will land up with the IT job from where
you can start your career.</p>
								<p>Not everyone breaks into Big Engineering colleges, for getting into IT world you
need skills and we will develop those skills. A perfect place to build your skills to
start your journey to get into big companies like Amazon, Google, Cisco etc.</p>
								<p>Are you Ready ?</p>
								<br/>
								<Link to="/about-us" className="btn"> More Details </Link>
							</div>
						</div>
						<div className="grid-6 grid-sm-12 grid-xs-12">
							<div className="section-content">
								<div className="imageholder">
									<img src={university} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="section">
				<div className="innerwrap">
					<div className="row">
						<div className="grid-12">
							<div className="section-title">
								<span>Achievers</span>
								<h2>Our Academics</h2>
							</div>
							<div className="section-content">
								<div className="achievers-list">
								<Slider className="achievers-list-slider" {...settings}>
									{[1,2,3,4,5,6,7,8,9].map((item, index) => (
										<div key={index} className="achievers-item">
											<div className="avatar">
												<img src={avatar} />
											<span className="ribbon"><ion-icon name="ribbon-outline"></ion-icon></span>
											</div>
											<div className="content">
												<h4>Full Name</h4>
												<span>Profile</span>
											</div>
										</div>
									))}
								</Slider>
								</div>
								<div className="achievers-action"><a href="#">Read More <ion-icon name="chevron-forward-outline"></ion-icon> </a></div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<div className="section">
				<div className="innerwrap">
					<div className="row">
						<div className="grid-12">
							<div className="section-title">
								<span>What We Do</span>
								<h2>Our Movement</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="grid-12">
							<div className="whawedo-list">
								
								<div className="whawedo-item">
								<ion-icon name="file-tray-stacked-outline"></ion-icon>
									<h3>Content</h3>
									<p>SavNƏT has designed a course which will keep on growing without paying anything extra, the mode of the classes would be online, so you can pursue the course right from your home and you will for sure become the IT architect in future, with the vision we have and will secure a good job.</p>
								</div>
								<div className="whawedo-item">
								<ion-icon name="earth-outline"></ion-icon>
									<h3>Outreach</h3>
									<p>You will join us to do your course  and it will not be limited  for developing your IT skills but at the same time we will work on your communication skills , personality , interview preparation, Industrial scenarios without any extra cost, once you join us, you are here with us and we will ensure you acquire all the skills, What happens if you dont get a job after completing a course, we will give you extra year / time to revise everything you have done with us with the ongoing batches unless you secure you job.</p>
								</div>
								<div className="whawedo-item">
								<ion-icon name="bookmarks-outline"></ion-icon>
									<h3>Career Seminar</h3>
									<p>It is very important to know what this is and how this will be done, for that we are doing the Virtual Webinar and we will explain all the sides and importance about the course and your career and where you can end up in future. Do you want to embark on this beautiful journey to build your skills ?</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="section movement">
				<div className="innerwrap">
					<div className="row">
						<div className="grid-12">
							<div className="movement-content">
							<h1>Our Education Movement</h1>
							<h3>Empowering the Community</h3>
							<p>Founded in 2021, SavNƏT's mission is to help the students becoming professional and mentoring them throughout their educational journey to ensure they are getting a job right after completing their course with us. Our mission is to ensure you complete your course with us, which will help you becoming an IT Architect in future in the field of Software Development, Networks , Clouds, Network security. You will gain all of the skills.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section topcourses ">
				<div className="innerwrap">
					<div className="row">
						<div className="section-title">
							<span>Top Courses</span>
							<h2>Browse Our Top Courses</h2>
						</div>
					</div>
					<div className="row">
						<div className="section-content">
							<Courses />
							{/* <div className="course-action">
								<Link to="/courses" className="btn btn-nulled">View All Courses</Link>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			{/* <Testimonials/>
			<div className="section">
				<div className="innerwrap">
					<div className="newsletters">
					<div className="row">
						<div className="grid-6">
							<h2>Newsletter to get in touch</h2>
						</div>
						<div className="grid-6">
							<form id="newsletter">
								<div className="formfield">
									<input type="text" placeholder="Enter your email id" />
									<button type="submit">Subscribe</button>
								</div>
							</form>
						</div>
					</div>
					</div>
				</div>
			</div> */}

		</Layout>
	)
}
export default Home;