import React, { useEffect } from 'react';
import "../assets/styles/testimonials.scss";
import Slider from 'react-slick';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >
<ion-icon name="chevron-forward-outline"></ion-icon>
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >
          <ion-icon name="chevron-back-outline"></ion-icon>
      </div>
    );
  }
  

const Testimonials = () => {

    const settings = {
        autoplay: true,
        dots: false,
        slidesToShow: 3,
        nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };

   return(
        <>
<div className="testimonials">


<section className="half-color-box">
 <div className="container spacer por">
     <div className="text-whtie text-center ml-3">
         <h1 className="bold">What Clients Say</h1>
         <p className="lead">
             Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem, at!
         </p>
     </div>Q
     <div className="innerwrap swiper-testimonial">
         <div className="swiper-wrapper">
             <Slider {...settings}>
             <div className="swiper-slide">
                 <div className="review-box">
                     <div className="media">
                         <img className="mr-3" src="https://picsum.photos/50/50" alt="Generic placeholder image" />
                         <div className="media-body">
                             <h5>Amanda Jackson</h5>
                             <p className="user-post">CEO, NRD Group</p>
                         </div>
                     </div>
                     <p className="read">
                         It has survived not only five centuries, but also the leap into electronic typesetting,
                         remaining essentially unchanged. It was popularised in the 1960s with the race.
                     </p>
                     <img className="quote" src="img/icons/quote.svg" alt="" />

                 </div>
             </div>
             <div className="swiper-slide">
                 <div className="review-box">
                     <div className="media">
                         <img className="mr-3" src="https://picsum.photos/50/50" alt="Generic placeholder image" />
                         <div className="media-body">
                             <h5>Amanda Jackson</h5>
                             <p className="user-post">CEO, NRD Group</p>
                         </div>
                     </div>
                     <p className="read">
                         It has survived not only five centuries, but also the leap into electronic typesetting,
                         remaining essentially unchanged. It was popularised in the 1960s with the race.
                     </p>
                     <img className="quote" src="img/icons/quote.svg" alt="" />
                 </div>
             </div>
             <div className="swiper-slide">
                 <div className="review-box">
                     <div className="media">
                         <img className="mr-3" src="https://picsum.photos/50/50" alt="Generic placeholder image" />
                         <div className="media-body">
                             <h5>Amanda Jackson</h5>
                             <p className="user-post">CEO, NRD Group</p>
                         </div>
                     </div>
                     <p className="read">
                         It has survived not only five centuries, but also the leap into electronic typesetting,
                         remaining essentially unchanged. It was popularised in the 1960s with the race.
                     </p>
                     <img className="quote" src="img/icons/quote.svg" alt="" />

                 </div>
             </div>
             <div className="swiper-slide">
                 <div className="review-box">
                     <div className="media">
                         <img className="mr-3" src="https://picsum.photos/50/50" alt="Generic placeholder image" />
                         <div className="media-body">
                             <h5>Amanda Jackson</h5>
                             <p className="user-post">CEO, NRD Group</p>
                         </div>
                     </div>
                     <p className="read">
                         It has survived not only five centuries, but also the leap into electronic typesetting,
                         remaining essentially unchanged. It was popularised in the 1960s with the race.
                     </p>
                     <img className="quote" src="img/icons/quote.svg" alt=""/>
                 </div>
             </div>
             </Slider>
         </div>

         

     </div>
 </div>
</section>
</div>
</>
   )
}

export default Testimonials;